import React from "react";
import {Row, Form, Col} from "react-bootstrap";

import "./index.css";
import DommusToggle from "../../../components/DommusToggle";
import helper from "../../../helpers/format";
import moment from "moment";

interface TaskInfoProps {
    infos: any;
}

export function TaskInfo({infos}: TaskInfoProps) {
    return (
        <>
            <div className="containerInfo">
                <DommusToggle title="Dados do chamado" toggle={true}>
                    <Row>
                        <Form.Group as={Col} controlId="formNome">
                            <div className="linhaInfo">
                                <div style={{fontWeight: 500}}>Número do chamado:</div>
                                <div style={{color: "#FF6D6D", marginLeft: 10, cursor: "pointer"}} onClick={() => window.open(`/?idChamado=${infos.ticket?.id_ticket}`)}>
                                    {infos.ticket?.tipo?.sigla}-{helper.zeroPad(infos.ticket?.id_ticket, 5)}
                                </div>
                            </div>
                        </Form.Group>
                        {infos.ticket?.tipo_chamado == 'AC' && <Form.Group as={Col} controlId="formNome">
                            <div className="linhaInfo">
                                <div style={{fontWeight: 500}}>Condomínio:</div>
                                <div style={{marginLeft: 10, color: "#444"}}>
                                    {helper.formataTextoParaTitulo(infos.dados_condominio?.razao_social)}
                                </div>
                            </div>
                        </Form.Group> || <>
                            <Form.Group as={Col} controlId="formNome">
                                <div className="linhaInfo">
                                    <div style={{fontWeight: 500}}>Processo:</div>
                                    <div style={{marginLeft: 10, color: "#444"}}>
                                        {helper.zeroPad(infos.ticket?.id_processo, 7)}
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formNome">
                                <div className="linhaInfo">
                                    <div style={{fontWeight: 500}}>Cliente:</div>
                                    <div style={{marginLeft: 10, color: "#444"}}>
                                        {infos.dados_processo?.proponente1?.nome}
                                    </div>
                                </div>
                            </Form.Group>
                        </>}
                    </Row>
                    {infos.ticket?.tipo_chamado == 'AC' && <Row>
                        <Form.Group as={Col} controlId="formNome">
                            <div className="linhaInfo">
                                <div style={{fontWeight: 500}}>Síndico:</div>
                                <div style={{marginLeft: 10, color: "#444"}}>
                                    {helper.formataTextoParaTitulo(infos.dados_condominio?.nome_sindico)}
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formNome">
                            <div className="linhaInfo">
                                <div style={{fontWeight: 500}}>CPF Síndico:</div>
                                <div style={{marginLeft: 10, color: "#444"}}>
                                    {infos.dados_condominio?.cpf_sindico || '---'}
                                </div>
                            </div>
                        </Form.Group>
                    </Row> || <></>}
                    <Row>
                        <Form.Group as={Col} controlId="formNome">
                            <div className="linhaInfo">
                                <div style={{fontWeight: 500}}>Assunto:</div>
                                <div style={{marginLeft: 10, color: "#444"}}>
                                    {infos.ticket?.assunto}
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formNome">
                            <div className="linhaInfo">
                                <div style={{fontWeight: 500}}>Categoria:</div>
                                <div style={{marginLeft: 10, color: "#444"}}>
                                    {infos.ticket?.categoria?.nome}
                                </div>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="formNome">
                            <div className="linhaInfo">
                                <div style={{fontWeight: 500}}>Motivo:</div>
                                <div style={{marginLeft: 10, color: "#444"}}>
                                    {infos.ticket?.motivo?.nome}
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formNome">
                            <div className="linhaInfo">
                                <div style={{fontWeight: 500}}>Categoria:</div>
                                <div style={{marginLeft: 10, color: "#444"}}>
                                    {infos.ticket?.tipo?.nome}
                                </div>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="formNome">
                            <div className="linhaInfo">
                                <div style={{fontWeight: 500}}>Status:</div>
                                <div style={{marginLeft: 10, color: "#444"}}>
                                    {infos.ticket?.status?.nome}
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formNome">
                            <div className="linhaInfo">
                                <div style={{fontWeight: 500}}>Substatus:</div>
                                <div style={{marginLeft: 10, color: "#444"}}>
                                    {infos?.ticket?.substatus != null ? infos.ticket?.substatus?.nome : "Não definido"}
                                </div>
                            </div>
                        </Form.Group>
                    </Row>
                </DommusToggle>

                <DommusToggle title="Dados da tarefa" toggle={true}>
                    <Row >
                        <Form.Group as={Col} controlId="formNome">
                            <div className="linhaInfo">
                                <div style={{fontWeight: 500}}>Título:</div>
                                <div style={{marginLeft: 10, color: "#444"}}>
                                    {infos?.titulo}
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formNome">
                            <div className="linhaInfo">
                                <div style={{fontWeight: 500}}>Tipo:</div>
                                <div style={{marginLeft: 10, color: "#444"}}>
                                    {infos.tipo_tarefa?.descricao}
                                </div>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="formNome">
                            <div className="linhaInfo">
                                <div style={{fontWeight: 500}}>Data de início:</div>
                                <div style={{marginLeft: 10, color: "#444"}}>
                                    {moment(infos?.data_inicio).format("DD/MM/YYYY HH:mm")}
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formNome">
                            <div className="linhaInfo">
                                <div style={{fontWeight: 500}}>Data de fim:</div>
                                <div style={{marginLeft: 10, color: "#444"}}>
                                    {moment(infos?.data_fim).format("DD/MM/YYYY HH:mm")}
                                </div>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="formNome">
                            <div className="linhaInfo">
                                <div style={{fontWeight: 500}}>Empreendimento:</div>
                                <div style={{marginLeft: 10, color: "#444"}}>
                                    {infos.dados_processo?.empreendimento?.nome || infos.empreendimento?.nome_empreendimento}
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formNome">
                            <div className="linhaInfo">
                                <div style={{fontWeight: 500}}>Detalhes:</div>
                                <div style={{marginLeft: 10, color: "#444"}}>
                                    {infos?.detalhes}
                                </div>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="formNome">
                            <div className="linhaInfo">
                                <div style={{fontWeight: 500}}>{infos.dados_processo?.tipo_chamado == 'AC' && 'Tipo' || 'Unidade'}:</div>
                                <div style={{marginLeft: 10, color: "#444"}}>
                                    {infos.dados_processo?.tipo_chamado == 'AC' ? 'Área Comum' : infos.dados_processo?.unidade?.unidade || "---" }
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formNome">
                            <div className="linhaInfo">
                                <div style={{fontWeight: 500}}>Descrição:</div>
                                <div style={{marginLeft: 10, color: "#444"}}>
                                    {infos.dados_processo?.unidade?.descricao || "---"}
                                </div>
                            </div>
                        </Form.Group>
                    </Row>
                </DommusToggle>
            </div>
        </>
    );
}
