import crm from "./crm";

const ticketService = {
  index: (filtros = null, limit, page) => {
    return crm.get(`/ticket`, { params: { filtros, limit, page } });
  },
  reabrirChamado: (idTicket) => {
    return crm.put(`/ticket/${idTicket}/reabertura`);
  },
  getArquivos: (id) => {
    return crm.get(`/ticket/${id}/arquivos`);
  },
  deletarArquivos: (id) => {
    return crm.delete(`/ticket/arquivo/${id}`);
  },
  store: (dados) => {
    let uri = "/ticket";
    return crm["post"].call(crm, uri, { ticket: dados });
  },
  update: (id, dados) => {
    let uri = "/ticket/" + id;
    return crm["put"].call(crm, uri, { ticket: dados });
  },
  getMensagemByTicket: (id) => {
    return crm.get("/ticket/" + id + "/mensagens");
  },
  storeMensagem: (id, dados) => {
    let uri = "/ticket/" + id + "/mensagem";
    return crm["post"].call(crm, uri, { mensagem: dados });
  },
  getNotaInternaByTicket: (id) => {
    return crm.get("/ticket/" + id + "/notas");
  },
  removeNotaInternaById: (id) => {
    return crm.delete("/nota_interna/" + id);
  },
  storeNotaInterna: (id, dados) => {
    let uri = "/ticket/" + id + "/nota";
    return crm["post"].call(crm, uri, { nota: dados });
  },
  historico: (id) => {
    return crm.get(`/ticket/historico/${id}`);
  },
  buscarTicketsPorStatusPaginado: (id, limit, page, filtros) => {
    return crm.get(
      `/ticket/ticketsPorStatus/${id}?limit=${limit}&page=${page}`,
      { params: filtros }
    );
  },
  find: (id) => {
    return crm.get(`/ticket/${id}`)
  },
  buscaMeiosComunicacao: () => {
    return crm.get(`/ticket/meio-comunicacao`)
  },
  buscaAtendimentoPorTicket: (idTicket) => {
    return crm.get(`/ticket/${idTicket}/atendimentos`)
  },
  buscaAtendimentoMensagens: (idAtendimento, page) => {
    return crm.get(`/atendimento/${idAtendimento}/mensagens/${page}`)
  },
  buscaGravacaoVoip: (callSsid) => {
    return crm.get(`/atendimento/gravacao/${callSsid}`)
  },
};

export default ticketService;
