import React, { useState, useRef, useContext, useEffect } from "react";
import {
    Button,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    FormControl,
    Nav,
    Row,
    Tab,
} from "react-bootstrap";
import { Icon } from "@iconify/react";
import overflowMenuVertical from "@iconify-icons/carbon/overflow-menu-vertical";
import sendOutlined from "@iconify-icons/ant-design/send-outlined";
import paperclipIcon from "@iconify-icons/mdi/paperclip";
import ModalDommus from "../../../../../components/Modal";
import ReactTooltip from "react-tooltip";
import { errorToast } from "../../../../../components/DommusToast";
import convertFilesToBase64 from "../../../../../helpers/ConvertFilesToBase64";
import "./input.css";
import {isBrowser, MobileView, BrowserView, isMobile, isTablet} from 'react-device-detect';
import Upload from "./Upload/upload";
import { Galeria } from "../../../../../components/Galeria";
import Swal from "sweetalert2";
import { ChamadoContext } from "../../../../../components/ContextoGlobal";
import { cachearContexto } from "../../../../../helpers/cachearContexto";
import configuracaoService from "../../../../../services/ConfiguracaoService";

export default function Entrada({ onSendMessage }) {    
    const [mensagem, setMensagem] = useState("");
    const [mensagemAnexos, setMensagemAnexos] = useState("");
    const [openModalUpload, setOpenModalUpload] = useState(false);
    const {limiteCaracteresNotaInterna} = useContext(ChamadoContext);
    const contextoChamados = useContext(ChamadoContext)
    const refInputMenssage = useRef();
    const refInputSection = useRef();

    useEffect(()=>{
        cachearContexto(contextoChamados.limiteCaracteresNotaInterna, contextoChamados.setLimiteCaracteresInternas, configuracaoService.buscarPorChave.bind(configuracaoService, 'limite_caracteres_notas_internas'));
    },[])

    const atualizarQuantidadeLinhasTextarea = () => {
        const textarea = refInputMenssage.current;
        textarea.rows = 1;
        const quantidadeLinhasTextearea = Math.ceil(
            textarea.scrollHeight /
                parseFloat(getComputedStyle(textarea).lineHeight)
        );
        textarea.rows =
            quantidadeLinhasTextearea >= 6 ? 6 : quantidadeLinhasTextearea - 1;
        if (textarea.rows >= 2) {
            textarea.style.borderTopLeftRadius = "10px";
            textarea.style.borderTopRightRadius = "10px";
        } else {
            textarea.style.borderTopLeftRadius = "0";
            textarea.style.borderTopRightRadius = "0";
        }
    };

    const onSubmit = () => {
        
        let mensagemInput = mensagem.trim() ?? null;
        if (mensagemInput && mensagemInput.length) {
            onSendMessage(mensagemInput);
            setMensagem("");
        } else {
            errorToast.fire({ text: "A mensagem não pode estar vazia." });
            setMensagem("");
        }
    };

    
    const enviarArquivos = async(arquivos)=>{
        if(arquivos.length && mensagemAnexos.length){

            let filesConverter = [];
            let filesLink = [];
            arquivos.forEach((arquivo)=>{
                if(arquivo instanceof File){
                    filesConverter.push(arquivo);
                }else{
                    filesLink.push(arquivo);
                }
            })

            let arquivosConvertidos = await convertFilesToBase64.convertFileToBase64(
                filesConverter
            );
            onSendMessage(mensagemAnexos, [...arquivosConvertidos, ...filesLink]);   
            setMensagemAnexos("");
            setOpenModalUpload(false);                 
        }else{            
            Swal.fire({
                titleText: "Ooops...",
                text: (!arquivos.length ? "Nenhum arquivo para ser enviado!" : "") + (!mensagemAnexos.length ? "Campo de mensagem está vazio" : ""),
                icon: "warning",
            });
        }        
    }

    return (
        <>
            <ReactTooltip />
            <div id="Input" ref={refInputSection}>
                <DropdownButton
                    className="btn-options"
                    id="dropMenuOpcoes"
                    size="sm"
                    title={<Icon icon={overflowMenuVertical} />}
                >
                    <Dropdown.Item
                        className="opcaoMenu"
                        onClick={() => {
                            setOpenModalUpload(true);
                        }}
                    >
                        <div className="item-menu opcao-enviar-arquivo">
                            <Icon icon={paperclipIcon} width="1.1rem" />
                            <span className="tituloEnviarImagem">
                                Imagens & Anexos
                            </span>
                        </div>
                    </Dropdown.Item>
                    {/* <Dropdown.Divider /> */}
                </DropdownButton>
                <FormControl
                    className="input-message-field"
                    aria-label="Mensagens"
                    ref={refInputMenssage}
                    value={mensagem}
                    placeholder="Mensagem"
                    as="textarea"
                    autoFocus={true}
                    rows={1}
                    onChange={(e) => {
                        if (e.target.value.length <= limiteCaracteresNotaInterna?.valor) {
                            atualizarQuantidadeLinhasTextarea();
                            setMensagem(e.target.value);
                        }
                    }}          
                    onFocus={()=>{
                        if(refInputSection.current){
                            if(isTablet){
                                refInputSection.current.style.paddingBottom = "10px";
                            }
                            else if(isMobile){
                                refInputSection.current.style.paddingBottom = "20px";
                            }
                        }
                    }}
                    onBlur={()=>{
                        if(isMobile || isTablet){
                            if(refInputSection.current){
                                refInputSection.current.style.paddingBottom = "0";
                            }
                        }
                    }}   
                />
                <Button
                    data-tip="Enviar mensagem"
                    className="btn-send"
                    onClick={() => {
                        onSubmit();
                    }}
                >
                    <Icon icon={sendOutlined} color="white" />
                </Button>
            </div>

            <ModalDommus
                titulo="Imagens & Anexos"
                size="lg"
                open={openModalUpload}
                close={() => {
                    setOpenModalUpload(false);
                }}
            >
                <Container fluid>
                    <Tab.Container defaultActiveKey="direto">
                        <Row>
                            <Col xs={12} md={12}>
                                <Nav fill variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="direto">
                                            {(isBrowser && "Envio ") || ""}
                                            Direto
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="imagens">
                                            {(isBrowser && "Galeria de ") || ""}
                                            Imagens
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="anexos">
                                            {(isBrowser && "Galeria de ") || ""}
                                            Arquivos
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col xs={12} md={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="direto">
                                        <div className="px-3 py-3 border rounded">
                                            <Upload
                                                mensagem={mensagemAnexos}
                                                setMensagemInput={setMensagemAnexos}
                                                enviarImagem={enviarArquivos}
                                                setOpenModalUpload={
                                                    setOpenModalUpload
                                                }
                                            />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="imagens">
                                        <BrowserView className="px-3 py-3 border rounded">
                                            <Galeria
                                                tipo="imagem"
                                                definirEstadoModal={
                                                    setOpenModalUpload
                                                }
                                                enviarArquivos={enviarArquivos}
                                                mensagem={mensagemAnexos}
                                                setMensagem={setMensagemAnexos}
                                            />
                                        </BrowserView>
                                        <MobileView>
                                            <Galeria
                                                tipo="imagem"
                                                definirEstadoModal={
                                                    setOpenModalUpload
                                                }
                                                enviarArquivos={enviarArquivos}
                                                mensagem={mensagemAnexos}
                                                setMensagem={setMensagemAnexos}
                                            />
                                        </MobileView>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="anexos">
                                        <BrowserView className="px-3 py-3 border rounded">
                                            <Galeria
                                                tipo="anexo"
                                                definirEstadoModal={
                                                    setOpenModalUpload
                                                }
                                                enviarArquivos={enviarArquivos}
                                                mensagem={mensagemAnexos}
                                                setMensagem={setMensagemAnexos}
                                            />
                                        </BrowserView>
                                        <MobileView>
                                            <Galeria
                                                tipo="anexo"
                                                definirEstadoModal={
                                                    setOpenModalUpload
                                                }
                                                enviarArquivos={enviarArquivos}
                                                mensagem={mensagemAnexos}
                                                setMensagem={setMensagemAnexos}
                                            />
                                        </MobileView>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>
            </ModalDommus>
        </>
    );
}
