import React from "react";
import Carousel from "react-elastic-carousel";
import InformacoesCadastrais from "../../pages/Widgets/MeusChamados/Chamado/ColunaDireita/informacoesCadastrais";
import { Alert, Col, Nav, Tab } from "react-bootstrap";
import Documentacao from "../../pages/Widgets/MeusChamados/Chamado/ColunaDireita/documentacao";
import "./index.css"

export function DommusCarouselProponente({ proponentes }) {
    
    return (
        <Carousel itemsToShow={1} renderArrow={() => <></>} className="carousel-proponente">
            {proponentes.map((proponente, index) => {
                return (
                    <div className="tab-proponente" key={index}>
                        <Tab.Container defaultActiveKey="dados">
                            <div className="container-tabs px-0">
                                <div className="header">
                                    <Nav variant="pills" className="menu_direita">
                                        <Col>
                                            <Nav.Item>
                                                <Nav.Link eventKey="dados">Dados</Nav.Link>
                                            </Nav.Item>
                                        </Col>
                                        <Col>
                                            <Nav.Item>
                                                <Nav.Link eventKey="documentacao">Documentação</Nav.Link>
                                            </Nav.Item>
                                        </Col>
                                    </Nav>
                                </div>
                                <Tab.Content>
                                    <Tab.Pane eventKey="dados">
                                        <InformacoesCadastrais key={index} cliente={proponente.dados} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="documentacao">
                                        {proponente?.arquivos && proponente?.arquivos?.length ? 
                                            <Documentacao documentacao={proponente.arquivos} />
                                            :
                                            <Alert className="text-center mt-2" variant="warning">Nenhum Documento Anexado!</Alert>
                                        }
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                );
            })}
        </Carousel>
    )
}